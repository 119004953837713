import '@ungap/global-this'
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux' 
import store from "./store"; 
import App from './app';

 
ReactDOM.render(
  <React.StrictMode>

    <Provider store={store} > 
          <App /> 
    </Provider>
  </React.StrictMode>,
  document.getElementById('shopOnRoot')
);

 