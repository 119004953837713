import React, { Component } from 'react'
import ShopOnBioUser from './shopOnBioUser';  
export default class app extends Component {
    render() {
        return (<ShopOnBioUser />)
    }
}


// import React  from 'react'
// import ShopOnBioUser from './shopOnBioUser';  
// import Home from './home';  
// import {  BrowserRouter, Route, Routes, useParams } from "react-router-dom";   

// const app = () => {
//     const ShopOnBio =()=> { const params = useParams(); window.wall_Id = params.id; window.isEditor = false; return <ShopOnBioUser />; }
    
//         return  <BrowserRouter>
//                     <Routes>
//                         <Route path="/" element={<Home />} />
//                         <Route path="*" element={<Home />} />
//                         <Route exact path="/:id" element={<ShopOnBio /> }/> 
//                     </Routes>
//                 </BrowserRouter> 
//     }
//     export default  app 