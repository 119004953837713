import { GET_SHOPON_POST_BYSLUG_REDUCER,  GET_SHOPON_PRODUCT_BYSLUG_REDUCER, IS_LOADER_REDUCER, IS_PRODUCT_LOADER_REDUCER, LOAD_MORE_POST_REDUCER, LOAD_MORE_PRODUCT_REDUCER  } from './reducersKeys'
const initialState = { 
    shopOnPosts: [],
    shoponDetail:[],
    shopOnProducts: [],
    after:'',
    hasPost:1,
    isloder:false,
    isProductLoder:false,
    hasProduct:1
}
export default function reducer(state = initialState, action) {
    switch (action.type) {
       
        case GET_SHOPON_POST_BYSLUG_REDUCER: {
            const { shoponDetail , postData , after} = action.payload
             shoponDetail['extraData'] =  shoponDetail?.extra_data ? JSON.parse(shoponDetail?.extra_data):{}
            return { ...state, shopOnPosts: postData, shoponDetail: shoponDetail, after:after}
        }

        case GET_SHOPON_PRODUCT_BYSLUG_REDUCER: { 
            return { ...state, shopOnProducts: action.payload }
        }

        case LOAD_MORE_POST_REDUCER: { 
            const { data , after, hasPost} = action.payload 
        //    const newPosts = { ...state.shopOnPosts, shopOnPosts: [...data] };
            const newPosts = [...state.shopOnPosts, ...data];
            return { ...state, shopOnPosts: newPosts, after: after, hasPost: hasPost , isloder: false};
        }
        case LOAD_MORE_PRODUCT_REDUCER: {
        //    const newPosts = { ...state.shopOnPosts, shopOnPosts: [...data] };
            const newPosts = [...state.shopOnProducts, ...action.payload];
            return { ...state, shopOnProducts: newPosts, isProductLoder: false, hasProduct:action.payload?.length < 10 ? 0 : 1};
        }
       case IS_LOADER_REDUCER: {
           return{...state, isloder:action.payload}
       }
       case IS_PRODUCT_LOADER_REDUCER: {
        return{...state, isProductLoder:action.payload}
    }
        


        
        default:
            return { ...state }
    }

}