import React, { Component } from 'react'
// import "./linkinbio.css"
import './shopOnbiouser.css'
import './react_global.css'
import './skeleton-loader.css'
import './SocialIcons.scss'
import parse from 'react-html-parser';
import Post from './elements/post'
import Products from './elements/products'
import { connect } from 'react-redux';
import { GET_SHOPON_USER_POST, GET_SHOPON_USER_PRODUCT, LOAD_MORE_POST, LOAD_MORE_PRODUCT, SHOW_MORE } from '../actions/shopOnBioUserAction';
import { CLOUDPATH, NO_CAMERA_IMAGE } from '../actions/api';
import { IS_LOADER_REDUCER } from '../reducers/reducersKeys'
import { Helmet } from "react-helmet";
import {HomePostPage_Skeleton} from '../skeleton/index'
import { HomeHeaderScript } from '../utils'

class LinkinBioUser extends Component {

    state = { browseDropDown: false, searchDropDown: false, readMore: false, postShow: false, productsShow: false, mainPosts: true, productDetail: false, postDetail: false, detailPostData: [], detailProductData: [], activeTab:1 , isLoading:true, offset:20}

    componentDidMount() {
        this.props.GET_SHOPON_USER_POST();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('touchmove', this.handleScroll);
    }

    onBackCall = () => {
        if (this.state.productDetail) this.setState({ browseDropDown: false, postShow: false, productsShow: true, mainPosts: true, postDetail: false, productDetail: false })
        else this.setState({ browseDropDown: false, postShow: false, productsShow: false, mainPosts: true, postDetail: false, productDetail: false })
    }

    detailPostShow = (item) => {
         this.setState({ detailPostData: item, postDetail: true }, () => window.scrollTo(0, 0))
         SHOW_MORE({action:2, wall:localStorage.getItem("wall_id"), post:item.referenceId, feed:item.feedId, model:'shopon', website_id:localStorage.getItem("website_id") })
        }

    detailProductShow = (item) => {
        this.setState({ detailProductData: item, productDetail: true }, () => window.scrollTo(0, 0))
        SHOW_MORE({type:1, action:2, wall:localStorage.getItem("wall_id"),   model:'shopon', website_id:localStorage.getItem("website_id"),  product_id:item.id})
    }

    detailPostShow_byref =(postId)=>{
        var getPost = this.props.shopOnUserData?.shopOnPosts?.filter((x)=>x.referenceId == postId)
        if(getPost && getPost[0]){
          this.setState({ detailPostData:getPost[0],  postDetail:true, activeTab:1 }, () => window.scrollTo(0, 0))
          SHOW_MORE({ action:2, wall:localStorage.getItem("wall_id"), post:getPost[0].referenceId, feed: getPost[0].feedId, model:'shopon', website_id:localStorage.getItem("website_id")})
        } 
        
    }

    detailProductShow_byref =(postId)=>{ 
        var getPost = this.props.shopOnUserData?.shopOnProducts?.filter((x)=>x.id == postId) 
        if(getPost && getPost[0]){
          this.setState({ detailProductData:getPost[0],  productDetail:true, activeTab:2 }, () => window.scrollTo(0, 0))
          SHOW_MORE({type:1, action:2, wall:localStorage.getItem("wall_id"),   model:'shopon', website_id:localStorage.getItem("website_id"),  product_id:postId}) 
        } 
        
    }


     isScrolledToBottom=()=> {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const totalHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight; 
        return scrollTop + clientHeight >= totalHeight-100;
      }

 
      handleScroll= ()=> {
        if (this.isScrolledToBottom()) {
            if(this.state.activeTab==2){
                 if(this.props.shopOnUserData?.hasProduct &&  this.state.isLoading){
                this.props.IS_LOADER()
                if(!(this.state.productDetail || this.state.postDetail)) this.setState({isLoading:false}, ()=> this.props.LOAD_MORE_PRODUCT(this.state.offset ,this.callBackOnsuccessProduct))
             } 
            }
            else{
                if(this.props.shopOnUserData?.hasPost  && this.state.isLoading){
               this.props.IS_LOADER()
               if(!(this.state.productDetail || this.state.postDetail)) this.setState({isLoading:false}, ()=> this.props.LOAD_MORE_POST(this.callBackOnsuccess))
            } 
           }
           
        }
      }

      callBackOnsuccess=()=>{
          this.setState({isLoading:true})
      }
      callBackOnsuccessProduct=()=>{
        this.setState({isLoading:true, offset:this.state.offset+10})
    }


    render() {
        const { browseDropDown, searchDropDown, readMore, postShow, productsShow, mainPosts, productDetail, postDetail, activeTab , detailPostData} = this.state
        const { shopOnUserData } = this.props
        const userdata = shopOnUserData.shoponDetail;

        return (shopOnUserData?.shopOnPosts?.length ?
            <>
            {HomeHeaderScript(shopOnUserData)}
            <div className={`lbUser_embed ${userdata?.extraData?.theme_type == 2 ? 'dark-theme' : ''}`} >
                {/* <Helmet><title>{userdata?.extraData?.title}</title></Helmet> */}
                <div className="lbUser_header">
                    
                    <div className={`lbUserheader_top ${productDetail ? 'header_only' : ''}`}>
                        <div className='lbUser_profile'>
                            {productDetail || postDetail ?
                                <div className="lb_backbutton" onClick={() => this.onBackCall()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                                        <path d="M0 9.93896C0 10.3208 0.145996 10.6465 0.449219 10.9385L9.20898 19.5073C9.44482 19.7544 9.75928 19.8779 10.1187 19.8779C10.8486 19.8779 11.4214 19.3164 11.4214 18.5752C11.4214 18.2158 11.2754 17.8901 11.0283 17.6431L3.1333 9.93896L11.0283 2.23486C11.2754 1.97656 11.4214 1.65088 11.4214 1.2915C11.4214 0.561523 10.8486 0 10.1187 0C9.75928 0 9.44482 0.123535 9.20898 0.370605L0.449219 8.93945C0.145996 9.23145 0.0112305 9.55713 0 9.93896Z" fill="#202223"></path>
                                    </svg>
                                </div> : ''}
                            <div className='lbUser_profileouter'>
                                {userdata?.extraData?.profile ? userdata?.extraData?.profile.includes('ui-avatars.com') ? <img  loading="lazy"  className='lbUser_profileImage' src={`${userdata?.extraData?.profile}`}  alt='Profile-image' /> : <img  loading="lazy"  className='lbUser_profileImage' src={`${CLOUDPATH}${userdata?.extraData?.profile}`}  alt='Profile-image' /> :  userdata?.extraData?.title?.charAt(0).toUpperCase()}
                            </div>
                            <div className='lb_title'> {userdata?.extraData?.title}</div>
                        </div>
                        {mainPosts ?
                            <div className={`lbUser_descrioption ${readMore ? 'active' : ''}`} >
                                {parse(userdata?.extraData?.description)}
                                {userdata?.extraData?.description?.length > 200 ? <div className='lbReadmore' onClick={() => this.setState({ readMore: !readMore })}>Read More</div> : ''}
                            </div> : ''}
                    </div>

                    {shopOnUserData?.shopOnProducts?.length > 0 ?
                        <div className='tcc_section'>
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${activeTab ==1 ? 'active' :''}`}  id="posts-tab" data-bs-toggle="pill" data-bs-target="#posts" type="button" role="tab" aria-controls="posts" aria-selected="true" onClick={()=>this.setState({activeTab:1})}>Posts</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${activeTab ==2 ? 'active' :''}`}  id="products-tab" data-bs-toggle="pill" data-bs-target="#products" type="button" role="tab" aria-controls="products" aria-selected="false" onClick={()=>this.setState({activeTab:2})}>Products</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className={`tab-pane fade ${activeTab ==1 ? 'show active' :''}`} id="posts" role="tabpanel" aria-labelledby="posts-tab">
                                    <Post shopOnUserData={shopOnUserData} postData={shopOnUserData?.shopOnPosts} postDetail={postDetail} detailPostData={this.state.detailPostData} detailPostShow={(data) => this.detailPostShow(data)} detailProductShow_byref={(postId)=>this.detailProductShow_byref(postId)}  activeTab={activeTab}/>
                                </div>
                                <div className={`tab-pane fade ${activeTab ==2 ? 'show active' :''}`} id="products" role="tabpanel" aria-labelledby="products-tab">
                                    <Products productData={shopOnUserData?.shopOnProducts} productDetail={productDetail} detailProductData={this.state.detailProductData} detailProductShow={(data) => this.detailProductShow(data)} detailPostShow_byref={(postId)=>this.detailPostShow_byref(postId)}/>
                                </div>
                            </div>
                        </div>
                        : <Post postData={shopOnUserData?.shopOnPosts}  detailPostData={this.state.detailPostData}  postDetail={postDetail} detailPostShow={(data) => this.detailPostShow(data)} activeTab={activeTab}/>}
                </div>
            </div>
            </> :
             <HomePostPage_Skeleton />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        shopOnUserData: state.shopOnUserData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        GET_SHOPON_USER_POST: (wallId, limit) => dispatch(GET_SHOPON_USER_POST(wallId, limit)),
        GET_SHOPON_USER_PRODUCT: (wallId, limit) => dispatch(GET_SHOPON_USER_PRODUCT(wallId, limit)),
        LOAD_MORE_POST:(callback)=>dispatch(LOAD_MORE_POST(callback)),
        LOAD_MORE_PRODUCT:(offset, callback)=>dispatch(LOAD_MORE_PRODUCT(offset, callback)),
        IS_LOADER:()=> dispatch({ type: IS_LOADER_REDUCER, payload:true})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkinBioUser);