import axios from 'axios';
import HttpClient from "./http-client";
import { GET_SHOPON_POST_BYSLUG_API, GET_SHOPON_PRODUCT_BYSLUG_API, LOAD_MORE_POST_API,LOAD_MORE_PRODUCT_API, SHOW_MORE_API   } from './api'
import {  GET_SHOPON_PRODUCT_BYSLUG_REDUCER, GET_SHOPON_POST_BYSLUG_REDUCER, LOAD_MORE_POST_REDUCER, LOAD_MORE_PRODUCT_REDUCER  } from '../reducers/reducersKeys';
import { TOAST_DISMISS, TOAST_ERROR, TOAST_SUCCESS } from '../utils';
import store from '../store';



export const GET_SHOPON_USER_POST= () => { 
    const slug = window.location.pathname.split("/").pop()
    if(slug){
    return (dispatch) => {
        new HttpClient().get(`${GET_SHOPON_POST_BYSLUG_API}/${slug}/40`).then((response) => {
            const { postData , response_code, shoponDetail , after} = response.data
            if (response_code == 200) {
                dispatch({ type: GET_SHOPON_POST_BYSLUG_REDUCER, payload: {shoponDetail, postData, after} });
                localStorage.setItem("wall_id", shoponDetail.wallId)
                localStorage.setItem("website_id", shoponDetail.id) 
            }
            dispatch(GET_SHOPON_USER_PRODUCT())
        }).catch(error => {
            window.location.replace('https://taggshop.io/shopon-bio');
            console.log("error", error)
        });
    }}
    else{
        window.location.replace('https://taggshop.io/shopon-bio');
    }
}


export const LOAD_MORE_POST= (callback) => { 
    var shopOnUserData = store.getState()?.shopOnUserData
    return (dispatch) => {
        new HttpClient().get(`${LOAD_MORE_POST_API}${localStorage.getItem('wall_id')}/10/${shopOnUserData?.after}`).then((response) => {
            const { postData , responseCode, data , after, hasPost } = response.data
            if (responseCode == 200) {
                dispatch({ type: LOAD_MORE_POST_REDUCER, payload: {data , after, hasPost} }); 
            }
            callback()
        }).catch(error => {
            callback()
            console.log("error", error)
        });
    }
}


export const GET_SHOPON_USER_PRODUCT= () => { 
    const slug = window.location.pathname.split("/").pop()
    return (dispatch) => {
        new HttpClient().get(`${GET_SHOPON_PRODUCT_BYSLUG_API}/${slug}/20`).then((response) => {
            const { responseData, responseCode, responseMessage } = response.data
            if (responseCode == 200) {
                dispatch({ type: GET_SHOPON_PRODUCT_BYSLUG_REDUCER, payload: responseData?.products});
            }
        }).catch(error => {
            console.log("error", error)
        });
    }
}

export const LOAD_MORE_PRODUCT= (offset, callback) => {
    var shopOnUserData = store.getState()?.shopOnUserData
    
    return (dispatch) => {
        new HttpClient().get(`${LOAD_MORE_PRODUCT_API}${localStorage.getItem('wall_id')}/10/${offset}`).then((response) => {
            const { responseData ,responseCode } = response.data 
            if (responseCode == 200) {
                dispatch({ type: LOAD_MORE_PRODUCT_REDUCER, payload: responseData?.products }); 
            }
            callback()
        }).catch(error => {
            callback()
            console.log("error", error)
        });
    }
}

export const SHOW_MORE= (obj) => { 
    var shopOnUserData = store.getState()?.shopOnUserData
    new HttpClient().post(SHOW_MORE_API,{...obj, owner: shopOnUserData?.shoponDetail.owner}).then((response) => {  }) 
}