export const HomePostPage_Skeleton=()=>{
    return <div className="lbUser_embed lbHomePageSkeleton">
        <div className="lbUser_header">
            <div className="lbUserheader_top ">
                <div className="lbUser_profile">
                    <div className="lbUser_profileouter">
                        <div className="sk_img"></div>
                    </div>
                    <div className="lb_title"> 
                        <div className="sk_line sk_line_20 sk_line_height_10 m-0"></div>
                    </div>
                </div>
                <div className="lbUser_descrioption">
                    <div className="sk_line sk_line_90 sk_line_height_10 mt-0"></div>
                    <div className="sk_line sk_line_60 sk_line_height_10 mt-0 mb-0"></div>
                </div>
            </div>
            <div className="tcc_section">
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <button className="nav-link">
                            <div className="sk_line sk_line_50 sk_line_height_16 m-auto "></div>
                        </button>
                        
                    </li>
                    <li className="nav-item">
                        <button className="nav-link">
                            <div className="sk_line sk_line_50 sk_line_height_16 m-auto"></div>
                        </button>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show active">
                        <div className="lb_posts">
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                            <div className="lb_image_holder">
                                <div className="lb_image_holder_bg">
                                    <div className="sk_img sk_img_square"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
} 


export const Loading_img_Skeleton=()=>{
    return  <div className="tab-content">
    <div className="tab-pane fade show active">
        <div className="lb_posts">
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
            <div className="lb_image_holder">
                <div className="lb_image_holder_bg">
                    <div className="sk_img sk_img_square"></div>
                </div>
            </div>
             
        </div>
    </div>
</div>
} 

export const PostDetail_Skeleton=()=>{
    return <div className=""></div>

}
