
import axios from 'axios';
import { HOST_URL, IS_LIVE } from './api' 

 

const requestInterceptor = (config) => {
    var headers = {}
    return {
        ...config,
        headers: {
            ...config.headers,
            ...headers
        },
    }
};

class HttpClient {
    constructor() {
        const options = { baseURL: HOST_URL };
        const instance = axios.create(options);
        instance.interceptors.request.use(requestInterceptor);
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
        return instance;
    }
}
export default HttpClient;
