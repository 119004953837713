import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetWrapper = ({ children }) => {
  return (
    <>
      <Helmet>
        {children}
      </Helmet>
    </>
  );
};

export default HelmetWrapper;
