import React, { Component } from 'react';
import Iframe from 'react-iframe';
import ReactPlayer from "react-player"
import { connect } from 'react-redux';
// import { IMG_LOAD_AFTER_COMPLETE_LAYOUT, IMG_LOAD_AFTER_COMPLETE_LAYOUT_FOR_POP_UP_VIDEO } from '../../../../utils'
import { NO_CAMERA_IMAGE } from '../../actions/api';

 export default class Media extends Component {
    constructor(props) {
        super(props); 
        this.state = { isHotspot: false, loadedImg:false, playVideo: true, }
        this.videoRef = React.createRef();
    }
   

    componentDidUpdate() {
        const { playVideo } = this.state;
        const { item , activeTab} = this.props;
         if((item.type === 3 || item.type === 5)){
             const video = this.videoRef.current; 
            if (playVideo && activeTab==1) { 
            video?.play(); 
            } else { 
            video?.pause(); 

            }
         }
        
      }

    

    render() {
        const { item, instaImg, ugc_products, wall, custmClass, ImageUrl, videoUrl } = this.props;
        const {loadedImg}=this.state;
        const isVideoError = false;
        return (
            <div className={`tb_media_holder ${(item.type === 2 || item.type === 4) ?'sk_img sk_img_autoHeight':''}`} key={`postID${item.id}`}  style={{aspectRatio:(item.mediaWidth/item.mediaHeight) }}>
                <div className={custmClass}>
                    {(item.type === 2 || item.type === 4) ? 
                    <div className="tb_img_holder"   >
                        <img src={ImageUrl} style={{height:'0', display:'none'}} onLoad={()=>this.setState({loadedImg:true})} />
                        {!loadedImg ? 
                            <div className="sk_loader_product">
                                <div></div>
                            </div>
                        :   <> 
                                <div className="tb_img_blur__0" style={{ backgroundImage: `url(${ImageUrl})` }}>
                                    <div></div>
                                </div>
                                <img loading="lazy" className='lbPost_detail_image' src={ImageUrl} alt='Post and Product' onError={(e) => e.target.src = NO_CAMERA_IMAGE}/>
                            </>
                        }
                    </div>

                    : item.type === 1 ? <></>
                        : (item.type === 3 || item.type === 5) &&
                            (item.link.indexOf("youtube") >= 0 ||
                                item.link.indexOf("youtu.be") >= 0 ||
                                item.link.indexOf("vimeo") >= 0 ||
                                item.link.indexOf("tumblr") >= 0 || (item.link.indexOf("vk") >= 0 && item.networkId == 30)) ? (
                                <div className="tb_img_holder">
                                    <div className="tb_modal_img_holder_001">
                                        <div className="tb_video_wrap">
                                            <Iframe
                                                className="tb-detail-image-iframe"
                                                ref={this.videoRef} 
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                src={`${videoUrl}`}
                                                allowFullScreen="true"
                                                style={{ width: "100%!important" }}
                                                //onError={(e) => { IMG_LOAD_AFTER_COMPLETE_LAYOUT_FOR_POP_UP_VIDEO(e) }}
                                                data-type="video"
                                                data-stories={item.stories}
                                                data-filter-id={item.filterId}
                                                data-network={item.networkId}
                                                data-link={item.link}
                                                data-wall-id={localStorage.getItem('wall_id')} 
                                                data-item-id={item.id}
                                                data-load={0}
                                                controls = {true}
                                            ></Iframe>
                                        </div>
                                    </div>
                                </div>
                            ) : isVideoError ? (
                                <div className="tb_img_holder"> 
                                    <div className="tb_modal_img_holder_001">
                                        <img src="https://app.taggbox.com/widget/image/no-camera.svg" style={{visibility: "visible!important", width: 180, margin: "20px auto", objectFit: "contain", }} />
                                    </div>
                                </div>
                            ) : (item.link.indexOf("facebook") >= 0) ? <div className="tb_img_holder">
                                    <div className="tb_modal_img_holder_001">
                                        <ReactPlayer
                                            style={{ display: 'contents' }}
                                            ref={this.videoRef} 
                                            className="tb-detail-image-iframe"
                                            url={videoUrl}
                                            data-type="video"
                                            data-stories={item.stories}
                                            data-filter-id={item.filterId}
                                            data-network={item.networkId}
                                            data-link={item.link}
                                            data-wall-id={localStorage.getItem('wall_id')} data-item-id={item.id}
                                            data-load={0}
                                            controls = {true}
                                            //onError={(e) => { IMG_LOAD_AFTER_COMPLETE_LAYOUT_FOR_POP_UP_VIDEO(e) }}
                                            autoPlay
                                        />
                                    </div>
                                </div> 
                                // : item.networkId == 28 ? <div className="tb_img_holder"><div className="tb_modal_img_holder_001">
                                //     <div className="tb_tiktok_video_wrap">
                                //          <Tiktoks data={item} key={item.id} />
                                //     </div></div>
                                // </div>
                                    :
                                    (
                                        <div className="tb_img_holder">
                                            <div className="tb_modal_img_holder_001">
                                                <div className="tb_video_wrap">
                                                    <video
                                                        src={videoUrl}
                                                        ref={this.videoRef} 
                                                        controls = {true}
                                                        autoPlay
                                                        data-type="video"
                                                        data-stories={item.stories}
                                                        data-filter-id={item.filterId}
                                                        data-network={item.networkId}
                                                        data-link={item.link}
                                                        //onError={(e) => { IMG_LOAD_AFTER_COMPLETE_LAYOUT_FOR_POP_UP_VIDEO(e) }}
                                                        data-load={0}
                                                        poster={item.postFileNew}
                                                        data-wall-id={localStorage.getItem('wall_id')} data-item-id={item.id}
                                                    ></video>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            {item.networkId == 24 ? (
                                <div className="tb_img_holder">
                                    <div className="tb_modal_img_holder_001">
                                        <img src="img/loading.gif" alt="" className="img-responsive spotify-img" style={{ visibility: "visible", position: "relative", transform: "translate(-50%, -50%)", top: "50%", left: "50%", display: "none", width: "60px", }} />
                                    </div>
                                </div>
                            ) : null}
                </div> 
            </div>
        );
    }
}

 