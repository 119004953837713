import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';

export default class postContent extends Component {
    state ={readMore:false}
    render() {
        const { readMore} = this.state
        return (
            <div className={`lbTagged_post_desc ${readMore ? 'active' :''}`} >
                {ReactHtmlParser(this.props.content)} <div className='lbReadmore' onClick={()=> this.setState({readMore:!readMore})}>Read More</div>
            </div>
        )
    }
}
