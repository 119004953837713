import React, { Component } from 'react'
import Post from './post'
import moment from 'moment';
import { NO_CAMERA_IMAGE } from '../../actions/api';
import { SHOW_MORE } from '../../actions/shopOnBioUserAction';
import Media from './Media';
import MediaSlider from './mediaSlider';
export default class postDetail extends Component {

  render() {
    const { detailPostData , detailProductShow_byref, activeTab} = this.props
    return (
      detailPostData ? <div className='lbPost_detail'>
        <div className='lbPost_detail_inner'>
          <div className='lbPost_detail_image_holder'> 
            {detailPostData?.imageList.length > 0 ?
             <MediaSlider item={detailPostData}/>
             : <Media item={detailPostData}  ImageUrl={detailPostData.filename} videoUrl={detailPostData.mediaUrl} activeTab={activeTab}/>}
          </div>
          <div className='lbPost_detail_author_detail'>
            <div className='lbPost_detail_author_inner'>
              <div className='lbPost_detail_author_image_holder sk_img sk_img_author_image'>
                <img loading="lazy" className='lbPost_detail_author_image' src={detailPostData?.author?.picture} alt='' onError={(e) => e.target.src = detailPostData?.author?.errorPic}/>
              </div>
              <div className='lbPost_detail_alignRight'>
                <div className='lbPost_detail_author_name'>
                  {detailPostData?.author?.name}
                </div>
                <div className='lbPost_detail_post_time'>
                  {moment(new Date(detailPostData?.createdAt * 1000)).fromNow()}
                </div>
              </div>
            </div>
            {detailPostData?.ugc_products && detailPostData?.ugc_products.length > 0 ?  detailPostData?.ugc_products.map((product, i) => {
              return <div className='lbpost_detail_product' key={`product_${product?.id}_${i}`}>
                <div className='lbpost_detail_product_box'>
                  <div className='lbpost_detail_product_image_holder'  onClick={() => detailProductShow_byref(product?.ugc_product)}>
                    <img loading="lazy"  className='lbpost_detail_product_image' src={`${product?.UgcProduct?.product_image}`} alt='' onError={(e) => e.target.src = NO_CAMERA_IMAGE}/>
                  </div>
                  <div className='lbpost_detail_product_content'>
                    <div className='lbpost_detail_product_title'  onClick={() => detailProductShow_byref(product?.ugc_product)}>{product?.UgcProduct?.product_title}</div>
                    <div className='lbpost_detail_product_price'>{product?.UgcProduct?.price_currency_symbol}{Math.trunc((product?.UgcProduct?.product_discount == 0 ? product?.UgcProduct?.product_price : product?.UgcProduct?.product_discount)*100)/100}</div>
                    <div className='lbpost_detail_product_button'>
                      <a href={product?.UgcProduct?.product_url} target="_blank" style={{ color:`#ffffff`}} 
                      onClick={()=>{ SHOW_MORE({type:2, action:2, wall:localStorage.getItem("wall_id"),   model:'shopon', website_id:localStorage.getItem("website_id"), post:detailPostData.referenceId, product_id:product.UgcProduct.id}) }}>
                        View on Website</a>
                    </div>
                  </div>
                </div>
              </div>
            }) :
               <div className="lbpost_detail_content"> {detailPostData?.content} </div>}
          </div>
        </div>

      </div> : ''
    )
  }
}
