import React, { Component } from 'react'
import Products from './products';
// import { Splide, SplideSlide } from '@splidejs/react-splide';
// import '@splidejs/react-splide/css'; 
import { NO_CAMERA_IMAGE } from '../../actions/api';
import PostContent from './postContent';
import { SHOW_MORE } from '../../actions/shopOnBioUserAction';


export default class productDetail extends Component {
  state ={readMore:false}
  render() {
    const { readMore} = this.state
    const {detailProductData, detailPostShow_byref}= this.props
    const setting = {arrows:false}
    return (
      <div className='lbProduct_detail'>
        <div className='lbProduct_detail_inner'>
          <div className='lbProduct_slider'>
            <div className="sk_img sk_img_square">
              <img loading="lazy" className='lb_PPimage' src={`${detailProductData?.product_image}`} onError={(e) => e.target.src = NO_CAMERA_IMAGE}/>
            </div>
            
            {/* <Splide hasTrack={true} options={setting}>
              <SplideSlide>
                <img className='lb_PPimage' src='https://images.pexels.com/photos/16134885/pexels-photo-16134885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='Post and Product' />
              </SplideSlide>
              <SplideSlide>
                <img className='lb_PPimage' src='https://images.pexels.com/photos/16796414/pexels-photo-16796414.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='Post and Product' />
              </SplideSlide>
              <SplideSlide>
                <img className='lb_PPimage' src='https://images.pexels.com/photos/16380570/pexels-photo-16380570.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='Post and Product' />
              </SplideSlide>
            </Splide> */}
          </div>
          <div className='lbProduct_heading'>
            <div className='lbProduct_title'>{detailProductData?.product_title}</div>
            <div className='lbProduct_price'>
            <div className='lbProduct_currency'>{detailProductData?.discount_currency_symbol}</div>{ detailProductData?.product_discount > 0 ? detailProductData?.product_discount  : detailProductData?.product_price}
              {detailProductData?.product_discount > 0 ?<del className='lbProduct_deleted_price'>
                <div className='lbProduct_currency'>{detailProductData?.discount_currency_symbol}</div>{detailProductData?.product_price}
              </del>:''}
            </div>
          </div>
          <div className='lbTagged_post'>
            <div className="lbTagged_post_wrapper">
          {detailProductData?.posts.map((item, i)=>{
            return <div className='lbTagged_post_box' key={i}>
              <div className='lbTagged_image_outer'  onClick={() => detailPostShow_byref(item.referenceId)}>
                <div className="sk_img sk_img_square_thumb">
                  <img loading="lazy" className='lbTagged_post_image' src={`${item?.postFileNew}`}  onError={(e) => e.target.src = `${NO_CAMERA_IMAGE}`} />
                </div>
              </div>
              <div className='lbTagged_content'>
                <div className='lbTagged_post_title' onClick={() => detailPostShow_byref(item.referenceId)}>
                  {item?.author?.username}
                </div>
                <PostContent content={item?.content}/>
              </div>
              <div className='lbTagged_sIcon'>
                <i className='fab fa-instagram'></i>
              </div>
            </div>
          })}
            </div>

            <div className='lbTagged_view_website'>
              <a href={detailProductData?.product_url} target="_blank"  
              onClick={()=>{ SHOW_MORE({type:2, action:2, wall:localStorage.getItem("wall_id"),   model:'shopon', website_id:localStorage.getItem("website_id"),  product_id:detailProductData?.id}) }}
              >View on Website</a> 
            </div>
          </div>
        </div> 
      </div>
    )
  }
}
