export const IS_LIVE = true;
export const IS_API_ACCESS_LIVE = true; 

const BASE_URL =  window.location.origin;

export const APP_API_URL = IS_API_ACCESS_LIVE ? `https://api.taggbox.com/` :`https://test.taggbox.com/`
export const API_URL = `${APP_API_URL}taggshop-api/v1`
export const HOST_URL = `${BASE_URL}`
export const CLOUDPATH = `https://cloud.taggbox.com/`
export const CLOUDPATH_TAGGSHOP = `https://cloud.taggbox.com/taggshop`
export const NO_CAMERA_IMAGE = `${CLOUDPATH_TAGGSHOP}/app/assets/media/error/no-camera.svg`;


//----------------HOST URL-------------------
export const GET_SHOPON_WALL_API = `${API_URL}/walls/getWall`  
export const GET_SHOPON_PRODUCT_API = `${API_URL}/shopon/getProducts`
export const GET_SHOPON_PRODUCT_BYSLUG_API = `${API_URL}/shopon/getProducts`
export const GET_SHOPON_POST_BYSLUG_API = `${API_URL}/shopon/getPosts`


export const LOAD_MORE_POST_API = `${API_URL}/shopon/load-more-posts/`
export const LOAD_MORE_PRODUCT_API = `${API_URL}/shopon/load-more-products/`



export const SHOW_MORE_API= `${API_URL}/walls/show-more`

