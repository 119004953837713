import { CLOUDPATH } from "../actions/api";
import HelmetWrapper from "../headerScript/HelmetWrapper";

export const HomeHeaderScript = (data) => {
    var { networks, hasTags } = getFeedData(data.shopOnPosts)
    if(hasTags.length > 3) hasTags = hasTags.slice(0, 3)
    return <HelmetWrapper>
        <title>{`${data.shoponDetail?.extraData.title}'s ${networks.length == 1 ? networks[0] == 'Instagram Business' ? 'Instagram Shop' : `${networks[0]} Shop` : 'Social Shop'} - Shopon Bio | Tagshop`}</title>
        <meta name="title" content={`${data.shoponDetail?.extraData.title} ${networks.length == 1 ? networks[0] == 'Instagram Business' ? 'Instagram Shop' : `${networks[0]} Shop` : 'Social Shop'} - Shopon Bio | Tagshop`} />
        <meta name="description" content={`Shop our ${networks.length == 1 ? networks[0] == 'Instagram Business' ? 'Instagram Feed' : `${networks[0]} Shop` : 'Social Feed'} ${data.shoponDetail?.extraData.title}. Find product inspiration from our user's #love. Tag ${data.shoponDetail?.extraData.title} to get featured. ${hasTags.join(", ")}`} />
        <meta name="keywords" content={`Tagshop, Shopon Bio, ${data.shoponDetail?.extraData.title}, ${hasTags.join(", ")}, #brandlove`} />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${data.shoponDetail?.extraData.title} ${networks.length == 1 ? networks[0] == 'Instagram Business' ? 'Instagram Shop' : `${networks[0]} Shop` : 'Social Shop'} - Shopon Bio | Tagshop`} />
        <meta property="og:description" content={`Shop our ${networks.length == 1 ? networks[0] == 'Instagram Business' ? 'Instagram Feed' : `${networks[0]} Shop` : 'Social Feed'} ${data.shoponDetail?.extraData.title}. Find product inspiration from our user's #love. Tag ${data.shoponDetail?.extraData.title} to get featured. ${hasTags.join(", ")}`} />
        <meta name="robots" content="index, follow" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Tagshop" />
        {/* <meta property="article:published_time" content="2023-12-19T21:31:00+00:00" />
        <meta property="article:modified_time" content="2024-02-21T04:59:16+00:00" /> */}
        {data.shoponDetail?.extraData?.profile ? <meta property="og:image" content={data.shoponDetail.extraData?.profile && data.shoponDetail.extraData?.profile.includes('ui-avatars.com') ? data.shoponDetail.extraData?.profile : `${CLOUDPATH}${data.shoponDetail.extraData?.profile}`} /> : null}
        {data.shoponDetail?.extraData?.profile ? <meta property="og:image:width" content="891" /> : null}
        {data.shoponDetail?.extraData?.profile ? <meta property="og:image:height" content="421" /> : null}
        {data.shoponDetail?.extraData?.profile ? <meta property="og:image:type" content="image/png" /> : null}
        <link rel="canonical" href={window.location.href} />
        {networks.includes('Twitter') ? <meta property="twitter:url" content={window.location.href} /> : null}
        {networks.includes('Twitter') ? <meta property="twitter:title" content={`${data.shoponDetail?.extraData.title} ${networks.length == 1 ? networks[0] == 'Instagram Business' ? 'Instagram Shop' : `${networks[0]} Shop` : 'Social Shop'} - Shopon Bio | Tagshop`}  /> : null}
    </HelmetWrapper>
}

const getFeedData = (posts) => {
    var hasTags = [];
    var networks = [];
    if (posts && posts.length > 0) {
        posts.map(post => {
            const network = post.network.name
            const hashTags = extractHashTagsFromContent(post.content)
            console.log(hashTags)
            if (!networks.includes(network)) networks.push(network)
            if (hasTags.length < 3) hasTags = [...hasTags, ...hashTags]
        })
    }
    return { networks, hasTags }
}

const extractHashTagsFromContent = (content) => {
    const hashTagPattern = /#\w+/g;
    let hashTags = content.match(hashTagPattern);
    return hashTags ? hashTags : [];
}

export const UPDATE_TWITTER_META = (post) => {

    if(post.network.id === 1) return (
        <HelmetWrapper>
            <meta name="twitter:card" content="summary" />
            <meta property="twitter:description" content={post.content} />
            <meta property="twitter:image" content={post.filename} />
        </HelmetWrapper>
    )
}