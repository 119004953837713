import React, { Component } from 'react'
import { NO_CAMERA_IMAGE } from '../../actions/api'
import PostDetail from './postDetail'
import { UPDATE_TWITTER_META } from '../../utils'

export default class post extends Component {
  render() {
    const { postDetail, hidePostDetail, shopOnUserData, postData, detailPostData, detailPostShow, detailProductShow_byref , activeTab} = this.props

    return <>
      { !postDetail ? 
       <>
        <div className='lb_posts'>
          {postData?.map((item, i) => {
            return  (
                      <div key={i} className='lb_image_holder' onClick={() => detailPostShow(item)}>
                        {UPDATE_TWITTER_META(item)}
                        <div className="lb_image_holder_bg">
                          <div className="sb_video_carousel_icon"> 
                            {(item.type === 3 || item.type === 5) ? 
                              <>
                                <div className="ts_video_icon">
                                  <div className="tb__icon tb-video-alt" style={{ fontSize: '12px' }}>
                                    <div></div>
                                  </div>
                                </div> 
                                {item?.imageList.length ?
                                  <div className="ts_social_icon">
                                    <div className="tb__icon tb-multiple tb_cs_carousal_icon" style={{ fontSize: '12px' }}>
                                      <div></div>
                                    </div>
                                  </div>
                                : '' } 
                              </> : 
                              item?.imageList.length ?
                                <div className="ts_social_icon">
                                  <div className="tb__icon tb-multiple tb_cs_carousal_icon" style={{ fontSize: '12px' }}>
                                    <div></div>
                                  </div>
                                </div>  : ''}
                          </div>

                          {item?.ugc_products.length > 0 ?
                          <div className="ts_shoppable_icon">
                            <div className="tb__icon tb-shopping-bag" style={{ fontSize: '12px' }}>
                              <div></div>
                            </div>
                          </div> : ''}

                          <img loading="lazy" className='lb_PPimage' src={item?.postFileNew} alt='Post and Product' onError={(e) => e.target.src = NO_CAMERA_IMAGE} />
                        </div>
                      </div>
                    )
          })}
        </div> 
        {shopOnUserData?.isloder && shopOnUserData?.hasPost ==1 ? <div className="lb_loadmore"> Loading...</div>:''}</>
        :
        <PostDetail detailPostData={detailPostData} detailProductShow_byref={detailProductShow_byref} activeTab={activeTab}/> }
      </>
    }
  }
