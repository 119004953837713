import React, { Component } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
 import '@splidejs/react-splide/css'; 
import { NO_CAMERA_IMAGE } from '../../actions/api';

export default class mediaSlider extends Component {
    state={mediaLoaded: false}
    render() {
        const {item}= this.props
        const { mediaLoaded } = this.state
        const setting = {perMove: 1,  arrows:false,  type : 'loop', perPage:1}
        return (
            <>
                <div className="sk_loader_product" style={mediaLoaded ? {display: 'none'} : {display: 'block'}}>
                    <div></div>
                </div>
                <Splide hasTrack={false} options={setting} style={!mediaLoaded ? {opacity: 0} : {opacity: 1}}>
                    <SplideTrack>
                        {item.imageList.map((imageItem, index) => {
                            return (
                                <>
                                <SplideSlide key={`psId${index}`} className="">
                                    <img loading="lazy" className='lbPost_detail_image' src={imageItem.postFile} onLoad={(e) => this.setState({mediaLoaded: true}, () => console.log('loaded'))} alt='Post and Product' onError={(e) => e.target.src = NO_CAMERA_IMAGE} />
                                    {/* <Media item={item}   ImageUrl={imageItem.postFile} videoUrl={imageItem.mediaUrl} /> */}
                                </SplideSlide>
                            </>
                            )
                        }
                        )}
                    </SplideTrack>
                </Splide> 
            </>
        )
    }
}
