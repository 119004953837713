import React, { Component } from 'react'
import { NO_CAMERA_IMAGE } from '../../actions/api'
import ProductDetail from './productDetail'

export default class products extends Component {

  render() {
    const { productDetail, productData, detailProductShow, detailProductData , detailPostShow_byref} = this.props

    return <>
      {!productDetail ? 
      <div className='lb_products'>
        {productData?.map((product, i) => {
          return (
            <div className='lbproduct_thumb' onClick={() => detailProductShow(product)} key={i}>
              <div className='lbproduct_image_holder sk_img_square_product'>
                <img loading="lazy" className='lbproduct_thumb_image' src={product?.product_image} alt='Post and Product' onError={(e) => e.target.src = NO_CAMERA_IMAGE} />
              </div>
              <div className='lbproduct_heading'>
                {product?.product_title}
              </div>
              <div className='lbproduct_price'>
                <div className='lbproduct_currency'>{product?.price_currency_symbol}</div>{product?.product_price}
              </div>
            </div>
          )
        })}
      </div> :<ProductDetail detailProductData={detailProductData} detailPostShow_byref={detailPostShow_byref}/> } </>
  }
}
